import "./homePage.css";
import {
  Box,
  Grid,
  Typography,
  Button,
  Card,
  CardContent,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import AppleIcon from "@mui/icons-material/Apple";
import AndroidIcon from "@mui/icons-material/Android";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import homePic from "../../assets/image.png";
import registerPic from "../../assets/RegisterPic.png";
// import registerPic2 from "../../assets/iphoneImg.png"
import googlePlay from "../../assets/GooglePlay.png";
import signup from "../../assets/signUp.png";
import convertedImg from "../../assets/imageToText.png";
import { useNavigate } from "react-router-dom";
import imageUpload from "../../assets/upload.png";

const Home = () => {

  const theme = useTheme()

  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'))
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))

  const navigate = useNavigate();

  //   const sliderSettings = {
  //     dots: false,
  //     infinite: false,
  //     speed: 300,
  //     slidesToShow: 3,
  //     slidesToScroll: 1,
  //     initialSlide: 0,
  //   };

  const handleSignUpNavigation = () => {
    navigate(`/signUp`);
  };

  return (
    <Box className="app-wrapper">
      <div style={{ background: "linear-gradient(135deg, #e0f7fa, #e0f7fa)" }}>
        {/* Section 1 */}
        <Grid container justifyContent="center" sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          marginTop: isMediumScreen ? "5%" : "0%",
          marginBottom: "10%",
        }}>
          <Grid item xs={12} md={6} sx={{ display: "flex", justifyContent: "center", padding: { xs: "10px" } }}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography className={isMediumScreen ? "welcomeTitle" : "welcomeTitleForMobile"} sx={{ marginTop: { xs: "50px", md: "100px" } }}>
                Welcome to TexApp
              </Typography>
              <Typography
                className= {isMediumScreen ? "welcomeDesc" : "welcomeDescForMobile"}
                sx={{
                  marginBottom: "15px",
                  whiteSpace: "pre-line",
                }}
              >
                Extract Text from Image,
                {"\n"}Turn picture into text.
              </Typography>

              <Grid
                item
                sx={{
                  display: "flex",
                  flexDirection: { xs: "row", md: "row" },
                  gap: "15px",
                  marginTop: isMediumScreen ? "30px" : "15px",
                }}
              >
                {isMediumScreen &&(
                  <>
                <Button
                  startIcon={<AppleIcon style={{ fontSize: isMediumScreen ? "52px" : "30px" }} />}
                  sx={{
                    display: "flex",
                    fontFamily: "inherit",
                    backgroundColor: "#000",
                    width: {xs: "135px", md: "220px"},
                    border: "1px solid #000",
                    color: "#fff",
                    height: {xs: "45px", md: "65px"},
                    borderRadius: "8px",
                    transition: "all 0.35s ease-in-out",
                    "&:hover": {
                      color: "#fff",
                      border: "1px solid #000",
                      transition: "ease-in-out",
                      backgroundColor: "#000",
                    },
                  }}
                  onClick={() =>
                    window.open("https://www.apple.com/in/app-store", "_blank")
                  }
                >
                  <Box>
                    <Typography
                      sx={{
                        fontSize: { xs: "8px", md: "12px" },
                        textTransform: "none",
                        textAlign: "start",
                        letterSpacing: "0.1em",
                      }}
                    >
                      Download on the
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "15px", md: "25px" },
                        fontWeight: "bold",
                        lineHeight: 1.1,
                        textTransform: "none",
                      }}
                    >
                      App Store
                    </Typography>
                  </Box>
                </Button>

                <Button
                  startIcon={
                    <img
                      src={googlePlay}
                      style={{ height: isMediumScreen ? "40px" : "25px", marginRight: "5px" }}
                    ></img>
                  }
                  sx={{
                    display: "flex",
                    fontFamily: "inherit",
                    backgroundColor: "#000",
                    width: {xs: "135px", md: "220px"},
                    border: "1px solid #000",
                    color: "#fff",
                    height: {xs: "45px", md: "65px"},
                    borderRadius: "5px",
                    transition: "all 0.35s ease-in-out",
                    "&:hover": {
                      color: "#fff",
                      border: "1px solid #000",
                      transition: "ease-in-out",
                      backgroundColor: "#000",
                    },
                  }}
                  onClick={() =>
                    window.open("https://play.google.com/store", "_blank")
                  }
                >
                  <Box>
                    <Typography
                      sx={{
                        fontSize: { xs: "9px", md: "12px" },
                        textTransform: "none",
                        textAlign: "start",
                        letterSpacing: "0.1em",
                      }}
                    >
                      Get it on
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "14px", md: "25px" },
                        fontWeight: "bold",
                        lineHeight: 1.1,
                        textTransform: "none",
                      }}
                    >
                      Google Play
                    </Typography>
                  </Box>
                </Button>
                </>
                )}
              </Grid>
            </div>
          </Grid>

          <Grid item xs={12} md={6} sx={{ display: "flex", justifyContent: {xs: "space-around", md: "center"} }}
          >
            <img src={homePic} className="screenShots" alt="Home" style={{width: isMediumScreen ? "280px" : "150px", height: isMediumScreen ? "550px" : "300px"}}></img>
            {isSmallScreen && (
               <Grid
                item
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "15px",
                  marginTop: isMediumScreen ? "30px" : "85px",
                }}
              >
                <Button
                  startIcon={<AppleIcon style={{ fontSize: isMediumScreen ? "52px" : "30px" }} />}
                  sx={{
                    display: "flex",
                    fontFamily: "inherit",
                    backgroundColor: "#000",
                    width: {xs: "135px", md: "220px"},
                    border: "1px solid #000",
                    color: "#fff",
                    height: {xs: "45px", md: "65px"},
                    borderRadius: "8px",
                    transition: "all 0.35s ease-in-out",
                    "&:hover": {
                      color: "#fff",
                      border: "1px solid #000",
                      transition: "ease-in-out",
                      backgroundColor: "#000",
                    },
                  }}
                  onClick={() =>
                    window.open("https://www.apple.com/in/app-store", "_blank")
                  }
                >
                  <Box>
                    <Typography
                      sx={{
                        fontSize: { xs: "8px", md: "12px" },
                        textTransform: "none",
                        textAlign: "start",
                        letterSpacing: "0.1em",
                      }}
                    >
                      Download on the
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "15px", md: "25px" },
                        fontWeight: "bold",
                        lineHeight: 1.1,
                        textTransform: "none",
                      }}
                    >
                      App Store
                    </Typography>
                  </Box>
                </Button>

                <Button
                  startIcon={
                    <img
                      src={googlePlay}
                      style={{ height: isMediumScreen ? "40px" : "25px", marginRight: "5px" }}
                    ></img>
                  }
                  sx={{
                    display: "flex",
                    fontFamily: "inherit",
                    backgroundColor: "#000",
                    width: {xs: "135px", md: "220px"},
                    border: "1px solid #000",
                    color: "#fff",
                    height: {xs: "45px", md: "65px"},
                    borderRadius: "5px",
                    transition: "all 0.35s ease-in-out",
                    "&:hover": {
                      color: "#fff",
                      border: "1px solid #000",
                      transition: "ease-in-out",
                      backgroundColor: "#000",
                    },
                  }}
                  onClick={() =>
                    window.open("https://play.google.com/store", "_blank")
                  }
                >
                  <Box>
                    <Typography
                      sx={{
                        fontSize: { xs: "9px", md: "12px" },
                        textTransform: "none",
                        textAlign: "start",
                        letterSpacing: "0.1em",
                      }}
                    >
                      Get it on
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { xs: "14px", md: "25px" },
                        fontWeight: "bold",
                        lineHeight: 1.1,
                        textTransform: "none",
                      }}
                    >
                      Google Play
                    </Typography>
                  </Box>
                </Button>
              </Grid>
              )}
          </Grid>
        </Grid>
      </div>

      {/* Section 2 */}
      <Grid
        container
        sx={{
          marginTop: "2%",
        }}
      >
        <Grid container item>
          <Box className="slickerBox" sx={{ background: "linear-gradient(135deg, #fff, #E8E8E8)", width: "100%" }}>
            <Typography
              sx={{
                fontSize: isMediumScreen ? "35px" : "22px",
                textAlign: "center",
                color: "#000",
                fontWeight: "bold",
                fontFamily: "'Verdana', sans-serif",
              }}
            >
              How to Extract text from Image?
            </Typography>
            {/* <Slider {...sliderSettings}>
              <div>
                <img
                  src={signup}
                  style={{ height: "400px", margin: "0 auto" }}
                  alt="Upload"
                ></img>
                <Typography className="slickerBoxDesc">
                  {" "}
                  <span style={{ fontWeight: "bold" }}> Step 1: </span> If you
                  are New, SignIn and Create
                  <br /> an Account
                </Typography>
              </div>
              <div>
                <img
                  src={imageUpload}
                  style={{ height: "400px", margin: "0 auto" }}
                  alt="Upload"
                ></img>
                <Typography className="slickerBoxDesc">
                  {" "}
                  <span style={{ fontWeight: "bold" }}> Step 2: </span> After
                  Login Upload or Drag <br />
                  your Image
                </Typography>
              </div>
              <div>
                <img
                  src={convertedImg}
                  style={{ height: "400px", margin: "0 auto" }}
                  alt="Upload"
                ></img>
                <Typography className="slickerBoxDesc">
                  <span style={{ fontWeight: "bold" }}> Step 3: </span>Now you
                  can, Copy the Extracted <br /> text
                </Typography>
              </div>
            </Slider> */}
            {/* <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "wrap",
                marginTop: "20px",
                alignItems: "stretch", 
              }}
            > */}

            <Grid container justifyContent="center" columnSpacing={6} rowSpacing={3}
              sx={{ marginTop: "15px" }}
            >
              <Grid item xs={12} md={4} sx={{ display: "flex", justifyContent: "center" }}>

                <Card
                  sx={{
                    backgroundColor: "#f0f4c3",
                    // backgroundColor: "#fff",
                    padding: "20px",
                    width: "100%",
                    borderRadius: "15px",
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
                    maxHeight: "95%",
                    transition: "transform 0.3s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.05)",
                    },
                  }}
                >
                  <img
                    src={signup}
                    style={{
                      height: isMediumScreen ? "250px" : "200px",
                      margin: "0 auto",
                      display: "block",
                      borderRadius: "10px",
                    }}
                    alt="Sign Up"
                  />
                  <CardContent>
                    <Typography
                      variant= {isMediumScreen ? "h6" : "h7"}
                      sx={{
                        fontWeight: "bold",
                        // color: "#3e2723" 
                        color: "#000"
                      }}
                    >
                      Step 1:
                    </Typography>
                    <Typography
                      sx={{
                        color: "#4e342e", 
                        fontSize: isMediumScreen ? "16px" : "13px"
                        // color: "#000"
                      }}>
                      If you are New, SignIn and Create an Account
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={4} sx={{ display: "flex", justifyContent: "center" }}>
                <Card
                  sx={{
                    backgroundColor: "#e1bee7",
                    // backgroundColor: "#fff",
                    width: "100%",
                    padding: "20px",
                    maxHeight: "95%",
                    borderRadius: "15px",
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                    transition: "transform 0.3s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.05)",
                    },
                  }}
                >
                  <img
                    src={imageUpload}
                    style={{ height: isMediumScreen ? "250px" : "200px", margin: "0 auto", display: "block", borderRadius: "10px" }}
                    alt="Upload"
                  />
                  <CardContent>
                    <Typography
                     variant= {isMediumScreen ? "h6" : "h7"}
                      sx={{
                        fontWeight: "bold",
                         color: "#6a1b9a" 
                        // color: "#000"
                      }}
                    >
                      Step 2:
                    </Typography>
                    <Typography
                      sx=
                      {{
                        color: "#7b1fa2", 
                        // color: "#000",
                        fontSize: isMediumScreen ? "16px" : "13px"
                      }}>
                      After Login Upload or Drag your Image
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>

              <Grid item xs={12} md={4} sx={{ display: "flex", justifyContent: "center" }}>
                <Card
                  sx={{
                    backgroundColor: "#b3e5fc",
                    // backgroundColor: "#fff",
                    padding: "20px",
                    width: "100%",
                    maxHeight: "95%",
                    borderRadius: "15px",
                    boxShadow: "0px 4px 20px rgba(0,0,0,0.1)",
                    transition: "transform 0.3s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.05)"
                    }
                  }}
                >
                  <img
                    src={convertedImg}
                    style={{ height: isMediumScreen ? "250px" : "200px", margin: "0 auto", display: "block", borderRadius: "10px" }}
                    alt="Converted Image"
                  />
                  <CardContent>
                    <Typography variant= {isMediumScreen ? "h6" : "h7"}
                      sx={{
                        fontWeight: 'bold',
                        color: "#01579b" 
                        // color: "#000"
                      }}
                    >
                      Step 3:
                    </Typography>
                    <Typography
                      sx={{
                        color: "#0277bd",
                        // color: "#000"
                        fontSize: isMediumScreen ? "16px" : "13px"
                      }}>
                      Now you can Copy the Extracted text
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
          {/* </Box> */}
        </Grid>
      </Grid>

      {/* Section 3 */}
      <Grid
        container
        sx={{
          marginTop: "10%",
          marginBottom: "55px",
          backgroundColor: "#fff"
        }}
      >
        <Grid
          container
          item
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            margin: "10px"
          }}
        >
          <Grid item xs={12} md={6}
            sx={{
              flexGrow: 1,
            }}
          >
            <img src={registerPic} className="screenShots" alt="Home" style={{width: isMediumScreen ? "280px" : "170px", height: isMediumScreen ? "550px" : "330px"}}></img>
          </Grid>
          {/* <img src={registerPic2} style={{width: "335px", height: "725px"}} className="screenShots" alt="Home"></img> */}
 
          <Grid item
            sx={{
              marginRight: "20px"
            }}
          >
            <Typography className={isMediumScreen ? "section2Head" : "section2HeadForMobile"}>
              Let the Conversion
              <br /> Start
            </Typography>
            <Typography className={isMediumScreen ? "section2Desc" : "section2DescForMobile"}>
              This tool scans the text from your images and <br /> extracts it
              for you in editable format.
            </Typography>
            <Button
              onClick={handleSignUpNavigation}
              sx={{
                marginTop: isMediumScreen ? "65px" : "25px",
                width: isMediumScreen ? "210px" : "130px",
                height: isMediumScreen ? "70px" : "40px",
                background: "linear-gradient(to right, #0aa2e0, #64d1fe)",
                color: "#fff",
                borderRadius: "30px",
                fontFamily: "inherit",
                fontSize: isMediumScreen ? "17px" : "12.5px",
                "&:hover": {
                  backgroundColor: "#64d1fe",
                  color: "white",
                  background: "linear-gradient(to right, #64d1fe, #0aa2e0)",
                },
              }}
            >
              Get Started
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Home;
