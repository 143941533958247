import React from 'react';
import { Button } from '@mui/material';

function MUIButton(props) {
  const { variant = 'contained', title, sx, ...restProps } = props;
  return (
    <Button variant={variant}
      {...restProps}
      disableElevation
      size="small"
      sx={[{
        textTransform: 'none', backgroundColor: '#fef426',
        color: '#324191', 
        fontFamily: 'inherit',
        minWidth:'150px',
        height: "33px",
        fontSize: "14px",
        marginBottom:'8px',
        "&:disabled": {
          backgroundColor: "gray",
          color: "white",
          cursor: "not-allowed",
          pointerEvents: "auto"
        },
        "&:hover": {
        //  backgroundColor: "#324191",
           backgroundColor: "#8B8000",
           color: "#fff"

        }
      }, { ...sx }]}>
      {title}
    </Button>
  )
};

export default MUIButton;
