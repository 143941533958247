import { Box, Container, Grid, IconButton, InputAdornment, Typography } from '@mui/material'
import React, { useState } from 'react'
import MuiTextField from '../../reusable_components/MuiTextField'
import MUIButton from '../../reusable_components/MuiSaveButton'
import { useNavigate } from 'react-router-dom'
import * as Yup from "yup";
import FrogImg from '../../assets/Forgotpassword.png'
import { Controller, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import EmailIcon from '@mui/icons-material/Email';
import useMediaQuery from '@mui/material/useMediaQuery';

const Forgotpassword = () => {

    const navigator = useNavigate()
    const [forgotEmail, setForgotEmail] = useState("");
    const isMobile = useMediaQuery('(max-width:600px)');


    const validationLogin = Yup.object().shape({
        forgotPassword: Yup.string().email('Invalid email address').required('Email is required'),

    });

    const { control, register, handleSubmit, formState: { errors, }, } = useForm({
        resolver: yupResolver(validationLogin),
        defaultValues: {
            forgotPassword: "",
        },
    });

    const handleForgotEmailChanges = (e) => {
        setForgotEmail(e.target.value)
    }
    const handleLoginPage = () => {
        navigator("/login")
    }

    const onSubmit = () => {
         
    }

    return (
        <div style={{ background: 'linear-gradient(135deg, #e0f7fa, #fff)' }} >
            <Box>
                <Grid container >
                    {!isMobile && (
                        <Grid item xs={12} md={6} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img src={FrogImg} alt='img'
                                style={{ width: '500px' }}></img>
                        </Grid>
                    )}
                    <Grid item xs={12} md={6} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                        <div><form onSubmit={handleSubmit(onSubmit)}>
                            <Container sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                minHeight: '86vh',
                            }} >
                                <Box
                                    sx={{
                                        margin: 'auto',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        border: '1px solid',
                                        borderColor: 'grey.300',
                                        padding: 2,
                                        borderRadius: "16px",
                                        backgroundColor: '#ffffff',
                                        width: "380px",
                                    }}
                                >
                                    <Typography className='loginTitle'>
                                        Forgot Password
                                    </Typography>

                                    <Typography
                                        marginTop={2} style={{ fontStyle: "italic", fontSize: "14px", textAlign: "center", fontWeight: "bold" }}>Enter your email address and we'll send you a new password to get back into your account</Typography>
                                    <Grid container xs={12} sx={{ marginTop: "30px" }} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                        <Controller
                                            control={control}
                                            name={"forgotPassword"}
                                            render={({ field }) => (
                                                <MuiTextField
                                                    {...register("password")}
                                                    {...field}
                                                    name="forgotPassword"
                                                    size="small"
                                                    label="Email"
                                                    type="text"
                                                    error={!!errors["forgotPassword"]}
                                                    //error={false}
                                                    InputLabelProps={{ className: 'login-textfield-label' }}
                                                    helperText={errors["forgotPassword"]?.message}
                                                    value={forgotEmail}
                                                    onChange={handleForgotEmailChanges}
                                                    style={{
                                                        width: "300px",
                                                        height: "40px",
                                                    }}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton edge="end" style={{ pointerEvents: 'none' }}>
                                                                    <EmailIcon sx={{ fontSize: 20 }} />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            )} />
                                    </Grid>


                                    <Grid container xs={12} marginTop={2} >
                                        <Grid item xs={2}></Grid>
                                        <Grid item xs={8} style={{ display: 'flex', flexDirection: "row", justifyContent: "space-evenly" }}>
                                            <MUIButton
                                                title="Recoved Password"
                                                type="submit"
                                            />
                                        </Grid>
                                        <Grid item xs={2}></Grid>
                                    </Grid>

                                    <Grid container xs={12} marginTop={2}>
                                        <Grid item xs={8}>
                                            <Typography style={{ fontWeight: 'bold', display: 'flex', cursor: "pointer", textDecoration: 'underline', }} onClick={handleLoginPage}>Cleck here go to login</Typography>
                                        </Grid><Grid item xs={4}></Grid>
                                    </Grid>


                                </Box>

                            </Container></form> </div>
                    </Grid>
                </Grid>

            </Box>
        </div>
    )
}

export default Forgotpassword
