import { Box, Button, Grid, IconButton, InputAdornment, InputLabel, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, useMediaQuery, useTheme } from "@mui/material"
import "./documentList.css"
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Link, useNavigate } from "react-router-dom";
import ReplayIcon from '@mui/icons-material/Replay';
import DescriptionIcon from '@mui/icons-material/Description';
import PhotoOutlinedIcon from '@mui/icons-material/PhotoOutlined';
import MuiTextField from "../../reusable_components/MuiTextField";
import SearchIcon from '@mui/icons-material/Search';
import { useState } from "react";
import TablePagination from "@mui/material/TablePagination";

const DocumentList = () => {

    const theme = useTheme()
    const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'))
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'))

    const [row, setRows] = useState([
        {
            name: "Captured Screenshot texts.jpg",
            DOG: "15/07/2024",
            type: "jpg",
            status: "Finished",
            uid: "A242N"
        },
        {
            name: "Record List screenshot.png",
            DOG: "10/07/2024",
            type: "png",
            status: "Finished",
            uid: "J242N"
        },
        {
            name: "Processed Nutrient Datas.pdf",
            DOG: "27/07/2024",
            type: "pdf",
            status: "Finished",
            uid: "Q242N"
        },
        {
            name: "Documents of Nutrients.pdf",
            DOG: "03/08/2024",
            type: "pdf",
            status: "Finished",
            uid: "L2428"
        },
        {
            name: "Image to Text.svg",
            DOG: "11/08/2024",
            type: "svg",
            status: "Finished",
            uid: "K2429"
        },
        {
            name: "Registration certificates.doc",
            DOG: "11/08/2024",
            type: "doc",
            status: "Finished",
            uid: "V2420"
        },
        {
            name: "Insurance Data certificates.txt",
            DOG: "11/08/2024",
            type: "txt",
            status: "Finished",
            uid: "A242N"
        },
        {
            name: "Educational certificates.doc",
            DOG: "11/08/2024",
            type: "doc",
            status: "Finished",
            uid: "A242N"
        },
        {
            name: "Office certificates.txt",
            DOG: "11/08/2024",
            type: "txt",
            status: "Finished",
            uid: "A242N"
        },
        {
            name: "Data certificates.txt",
            DOG: "11/08/2024",
            type: "txt",
            status: "Finished",
            uid: "A242N"
        },
        {
            name: "Office Documents.pdf",
            DOG: "11/08/2024",
            type: "pdf",
            status: "Finished",
            uid: "A242N"
        }
    ]);
    const [searchKeyword, setSearchKeyword] = useState("")

    const navigate = useNavigate()

    const handleEditNavigate = () => {
        navigate(`/textEditer`)
    }

    const handleListNavigation = () => {
        navigate(`/imageUpload`)
    }

    const handleFilter = (e) => {
        const value = e.target.value
        setSearchKeyword(value)
    }

    const filteredRows = row?.filter((item) => (
        item.name.toLowerCase().includes(searchKeyword)
    ))

    const [rpg, setrpg] = useState(10);
    const [pg, setpg] = useState(0);

    function handleChangeRowsPerPage(event) {
        setrpg(parseInt(event.target.value, 10));
        setpg(0);
    }

    function handleChangePage(event, newpage) {
        setpg(newpage);
    }

    return (
        <div style={{ background: 'linear-gradient(135deg, #e0f7fa, #fff)', minHeight: "90vh" }}>
            <Box sx={{ paddingTop: "10px" }}>
                <Typography className={isMediumScreen ? "pageTitle" : "pageTitleForMobile"} sx={{ marginLeft: isMediumScreen ? "16.5px" : "10px" }}
                >
                    List of Documents
                </Typography>
                <Grid container sx={{ display: "flex", justifyContent: "center", marginTop: "15px" }}>
                    <Grid item xs={11.7}>

                        <Grid item sx={{ marginBottom: "20px" }}>
                            <MuiTextField
                                placeholder="Search File"
                                size="small"
                                sx={{
                                    width: { md: "20%", xs: "50%" },
                                    backgroundColor: "#fff",
                                    borderRadius: "14px"
                                }}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon sx={{ color: "#989898" }} />
                                        </InputAdornment>
                                    ),
                                }}
                                value={searchKeyword}
                                onChange={handleFilter}
                            >

                            </MuiTextField>
                        </Grid>

                        <Paper sx={{ width: '100%', overflow: 'hidden', marginBottom: "25px" }}>
                            <TableContainer component={Paper}>
                                <Box sx={{ overflowX: 'auto', maxHeight: '340px' }}>
                                    <Table sx={{ minWidth: 1250 }} aria-label="Table">
                                        <TableHead style={{
                                            position: 'sticky',
                                            top: 0,
                                            zIndex: 10,
                                            width: '100%',
                                        }}>
                                            <TableRow className="tableHeaderStyle">
                                                <TableCell className={isMediumScreen ? "tableHeadersCell" : "tableHeadersCellForMobile"} sx={{ width: "35%" }}>File Name</TableCell>
                                                <TableCell className={isMediumScreen ? "tableHeadersCell" : "tableHeadersCellForMobile"} sx={{ width: "4%" }}>Id</TableCell>
                                                <TableCell className={isMediumScreen ? "tableHeadersCell" : "tableHeadersCellForMobile"} sx={{ width: "5%" }}>Scan Date</TableCell>
                                                <TableCell className={isMediumScreen ? "tableHeadersCell" : "tableHeadersCellForMobile"} sx={{ width: "5%" }}>File Type</TableCell>
                                                <TableCell className={isMediumScreen ? "tableHeadersCell" : "tableHeadersCellForMobile"} sx={{ width: "2%" }}>Status</TableCell>
                                                <TableCell className={isMediumScreen ? "tableHeadersCell" : "tableHeadersCellForMobile"} sx={{ width: "2%" }}>Edit</TableCell>
                                                <TableCell className={isMediumScreen ? "tableHeadersCell" : "tableHeadersCellForMobile"} sx={{ width: "2%" }}>Delete</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {filteredRows?.slice(pg * rpg, pg *
                                                rpg + rpg).map((document, index) => (
                                                    <TableRow key={index} sx={{
                                                        '&:nth-of-type(odd)': { backgroundColor: '#f9f9f9' },
                                                        '&:hover': { backgroundColor: '#f1f1f1' }
                                                    }}>

                                                        <TableCell sx={{ fontWeight: "bold", padding: "5px" }}>
                                                            <div style={{ display: "flex", alignItems: "center", gap: "7px", fontSize: isSmallScreen && "12px" }}>
                                                                {document?.type === "pdf" || document?.type === "txt" || document?.type === "doc" ? <DescriptionIcon sx={{ color: document?.type === "doc" ? "#007FFF" : "red", fontSize: "20px" }} /> : <PhotoOutlinedIcon sx={{ color: "#4B0082", fontSize: "20px" }} />}
                                                                {document.name}
                                                            </div>
                                                        </TableCell>

                                                        <TableCell component="th" scope="row" className={isMediumScreen ? "tableBodysTableCell" : "tableBodysTableCellForMobile"}>
                                                            {document.uid}
                                                        </TableCell>

                                                        <TableCell className={isMediumScreen ? "tableBodysTableCell" : "tableBodysTableCellForMobile"}>
                                                            {document.DOG}
                                                        </TableCell>

                                                        <TableCell className={isMediumScreen ? "tableBodysTableCell" : "tableBodysTableCellForMobile"}>
                                                            {document.type}
                                                        </TableCell>

                                                        <TableCell className={isMediumScreen ? "tableBodysTableCell" : "tableBodysTableCellForMobile"}>
                                                            {document.status}
                                                        </TableCell>

                                                        <TableCell sx={{ padding: "5px" }}>
                                                            <IconButton onClick={handleEditNavigate}>
                                                                <Tooltip title="Edit">
                                                                    <EditOutlinedIcon sx={{ color: "#64d1fe", fontSize: isMediumScreen ? "20px" : "17px" }} />
                                                                </Tooltip>
                                                            </IconButton>
                                                        </TableCell>

                                                        <TableCell sx={{ padding: "5px" }}>
                                                            <IconButton >
                                                                <Tooltip title="Delete">
                                                                    <DeleteOutlinedIcon sx={{ color: "#FA5F55", fontSize: isMediumScreen ? "20px" : "17px" }} />
                                                                </Tooltip>
                                                            </IconButton>
                                                        </TableCell>

                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </Box>
                            </TableContainer>
                            <TablePagination
                                rowsPerPageOptions={[5, 10]}
                                count={row.length}
                                page={pg}
                                rowsPerPage={rpg}
                                component="div"
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                onPageChange={handleChangePage}
                                sx={{
                                    '& .MuiTablePagination-toolbar': {
                                        minHeight: isSmallScreen ? '30px' : "none"
                                    },
                                    '& .MuiTablePagination-selectLabel, & .MuiInputBase-root, & .MuiTablePagination-displayedRows': {
                                        fontSize: isSmallScreen ? '0.65rem' : "0.8rem"
                                    },
                                    '& .MuiTablePagination-selectIcon': {
                                        fontSize: '1rem'
                                    },
                                    '& .MuiIconButton-root': {
                                        padding: '4px',
                                        height: isSmallScreen ? "0.5em" : "none"
                                    },
                                    "& .MuiSvgIcon-root": {
                                        height: isSmallScreen ? "0.8em" : "0.9em"
                                    }
                                }}
                            /></Paper>

                        <Grid item sx={{ marginBottom: "25px", display: "flex", justifyContent: "center" }}>
                            <Button startIcon={<ReplayIcon />}
                                onClick={handleListNavigation}
                                sx={{
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: "#0aa2e0",
                                    fontFamily: "inherit",
                                    textTransform: "none",
                                    color: "white",
                                    width: isMediumScreen ? "110px" : "90px",
                                    fontSize: isMediumScreen ? "13px" : "10px",
                                    "&:hover": {
                                        backgroundColor: "#0072b0"
                                    }
                                }}>Start Over</Button>
                        </Grid>


                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}
export default DocumentList