import React from 'react'
import AlertStack from './reusable_components/AlertStack'
import AuthenticatedRoutes from './serviceHistory/AuthenticatedRoutes'
import UnAuthenticatedRoutes from './serviceHistory/UnAuthenticatedRoutes'

const App = () => {

  const isAuthenticated = true; // Replace with actual authentication logic

  const getRoutes = () => {
    if (isAuthenticated) {
      return <AuthenticatedRoutes />;
    } else {
      return <UnAuthenticatedRoutes />;
    }
  }

  // const getRoutes = () => {
  //   if (token) {
  //     return <AuthenticatedRoutes />
  //   }
  //   if (!token) {
  //     return <UnAuthenticatedRoutes />
  //   }
  // }


  return (
    <div>
      {getRoutes()}
      <AlertStack/>
    </div>
  )
}

export default App
