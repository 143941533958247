import React, { useState } from 'react'
import MuiTextField from '../../reusable_components/MuiTextField'
import { Box, Container, Grid, IconButton, InputAdornment, Typography } from '@mui/material'
import MUIButton from '../../reusable_components/MuiSaveButton';
import { useNavigate } from 'react-router-dom';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import SignUpImg from "../../assets/signUp.png"
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import useMediaQuery from '@mui/material/useMediaQuery';

const SignUp = () => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:600px)');

    const [showConformPassword, setShowConformPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);

    const validationSchema = Yup.object().shape({
        eMail: Yup.string().email('Invalid email address').required('Email is required'),
        firstName: Yup.string().required("First Name is required").matches(/^[A-Za-z\s]+$/i, "First Name shouldn't allow special characters"),
        lastName: Yup.string().required("Last Name is required").matches(/^[A-Za-z\s]+$/i, "Last Name shouldn't allow special characters"),
        mobileNumber: Yup.string()
            .required('Phone number is required')
            .matches(/^\d{10}$/, 'Phone number should be 10 digits'),
        password: Yup.string().required('Password is required'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Password is required'),
    });

    const { control, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {
            firstName: "",
            lastName: "",
            mobileNumber: "",
            eMail: "",
            password: "",
            confirmPassword: "",
        },
    });

    const onSubmit = (data) => {
        console.log("Form Data: ", data);
        navigate('/imageUpload');
    }

    const handleSignInPassword = () => {
        navigate('/login');
    }

    return (
        <div style={{ background: 'linear-gradient(135deg, #e0f7fa, #fff)' }} >
            <Box
            // sx={{ backgroundColor: "#f5f5f5" }}
            >
                <Grid container>
                    {!isMobile && (
                        <Grid item xs={12} md={6} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img src={SignUpImg} alt='img' style={{ width: '500px' }} />
                        </Grid>
                    )}
                    <Grid item xs={12} md={6} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '86vh' }}>
                                    <Box
                                        sx={{
                                            margin: 'auto',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            border: '1px solid',
                                            borderColor: 'grey.300',
                                            padding: 2,
                                            borderRadius: "16px",
                                            backgroundColor: '#ffffff',
                                            width: "350px",
                                        }}
                                    >
                                        <Typography className='loginTitle'>
                                            Sign Up
                                        </Typography>
                                        <Typography marginTop={0.5} style={{ fontStyle: 'italic', fontSize: '13px', textAlign: 'center', fontWeight: 'bold' }}>
                                            Register to be more secure and personalized experience
                                        </Typography>
                                        <Grid container xs={12} sx={{ marginTop: "10px" }} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                            <Grid item>
                                                <Controller
                                                    control={control}
                                                    name={"firstName"}
                                                    render={({ field }) => (
                                                        <MuiTextField
                                                            {...field}
                                                            name="firstName"
                                                            type="text"
                                                            size="small"
                                                            label="First name"
                                                            error={!!errors.firstName}
                                                            helperText={errors.firstName?.message}
                                                            sx={{ width: "300px", height: "40px" }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end" style={{ pointerEvents: 'none' }}>
                                                                        <IconButton edge="end">
                                                                            <PersonIcon sx={{ fontSize: 20 }} />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Controller
                                                    control={control}
                                                    name={"lastName"}
                                                    render={({ field }) => (
                                                        <MuiTextField
                                                            {...field}
                                                            name="lastName"
                                                            type="text"
                                                            size="small"
                                                            label="Last Name"
                                                            error={!!errors.lastName}
                                                            helperText={errors.lastName?.message}
                                                            sx={{ width: "300px", height: "40px", marginTop: "10px" }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton edge="end" style={{ pointerEvents: 'none' }}>
                                                                            <PersonIcon sx={{ fontSize: 20 }} />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Controller
                                                    control={control}
                                                    name={"mobileNumber"}
                                                    render={({ field }) => (
                                                        <MuiTextField
                                                            {...field}
                                                            name="mobileNumber"
                                                            type="text"
                                                            size="small"
                                                            label="Phone Number"
                                                            error={!!errors.mobileNumber}
                                                            helperText={errors.mobileNumber?.message}
                                                            sx={{ width: "300px", height: "40px", marginTop: "10px" }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton edge="end" style={{ pointerEvents: 'none' }}>
                                                                            <PhoneIcon sx={{ fontSize: 20 }} />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Controller
                                                    control={control}
                                                    name={"eMail"}
                                                    render={({ field }) => (
                                                        <MuiTextField
                                                            {...field}
                                                            name="eMail"
                                                            type="text"
                                                            size="small"
                                                            label="Email"
                                                            error={!!errors.eMail}
                                                            helperText={errors.eMail?.message}
                                                            sx={{ width: "300px", height: "40px", marginTop: "10px" }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton edge="end" style={{ pointerEvents: 'none' }}>
                                                                            <EmailIcon sx={{ fontSize: 20 }} />
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Controller
                                                    control={control}
                                                    name={"password"}
                                                    render={({ field }) => (
                                                        <MuiTextField
                                                            {...field}
                                                            name="password"
                                                            type={showNewPassword ? 'text' : 'password'}
                                                            size="small"
                                                            label="Password"
                                                            error={!!errors.password}
                                                            helperText={errors.password?.message}
                                                            sx={{ width: "300px", height: "40px", marginTop: "10px" }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton onClick={() => setShowNewPassword(!showNewPassword)} edge="end">
                                                                            {showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <Controller
                                                    control={control}
                                                    name={"confirmPassword"}
                                                    render={({ field }) => (
                                                        <MuiTextField
                                                            {...field}
                                                            name="confirmPassword"
                                                            type={showConformPassword ? 'text' : 'password'}
                                                            size="small"
                                                            label="Confirm Password"
                                                            error={!!errors.confirmPassword}
                                                            helperText={errors.confirmPassword?.message}
                                                            sx={{ width: "300px", height: "40px", marginTop: "10px" }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <IconButton onClick={() => setShowConformPassword(!showConformPassword)} edge="end">
                                                                            {showConformPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                                        </IconButton>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container xs={12} marginTop={1}>
                                            <Grid item xs={4}></Grid>
                                            <Grid item xs={4} style={{ display: "flex", justifyContent: "center" }}>
                                                <MUIButton title="Register" type="submit" />
                                            </Grid>
                                            <Grid item xs={4}></Grid>
                                        </Grid>

                                        <Grid container>
                                            <Grid item style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}> <Typography marginTop={0.5} style={{ fontSize: '14px' }}>
                                                Already have an account?
                                            </Typography>
                                                <Typography
                                                    style={{ fontSize: '14px', cursor: 'pointer', color: 'blue', marginLeft: '4px', textDecoration: 'underline' }}
                                                    onClick={handleSignInPassword}
                                                >
                                                    Login
                                                </Typography>
                                            </Grid>
                                            <Grid item></Grid>
                                            <Grid item></Grid>
                                        </Grid>

                                    </Box>
                                </Container>
                            </form>
                        </div>
                    </Grid>
                </Grid>
            </Box></div>
    )
}

export default SignUp;
