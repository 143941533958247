import { yupResolver } from '@hookform/resolvers/yup';
import EmailIcon from '@mui/icons-material/Email';
import PersonIcon from '@mui/icons-material/Person';
import PhoneIcon from '@mui/icons-material/Phone';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Container, createTheme, Grid, IconButton, InputAdornment, Tab, Typography, useMediaQuery } from '@mui/material';
import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import 'react-phone-input-2/lib/style.css';
import * as Yup from "yup";
import MUIButton from '../../reusable_components/MuiSaveButton';
import MuiTextField from '../../reusable_components/MuiTextField';
import { SnackbarContext } from '../../reusable_components/Snackbar';


const theme = createTheme();

const Profile = () => {

    const { displaySnackbar } = useContext(SnackbarContext)

    const [selectedTab, setSelectedTab] = useState("1");

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    //=============================================================================================

    const profileSchema = Yup.object().shape({
        firstName: Yup.string().required("First Name is required").matches(/^[A-Za-z\s]+$/i, "First Name shouldn't allow special characters"),
        lastName: Yup.string().required("Last Name is required").matches(/^[A-Za-z\s]+$/i, "Last Name shouldn't allow special characters"),
        mobileNumber: Yup.string()
            .required('Phone number is required')
            .matches(/^\d{10}$/, 'Phone number should be 10 digits'),
    });



    const passwordSchema = Yup.object().shape({
        oldPassword: Yup.string().required('Old Password is required'),
        newPassword: Yup.string().required('New Password is required')
            .min(8, 'Password must be at least 8 characters')
            .matches(/[A-Z]/, 'Password must contain at least one capital letter')
            .matches(/[0-9]/, 'Password must contain at least one number')
            .matches(/[@$!%*?&#]/, 'Password must contain at least one special character'),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Password is required'),
    });

    const { handleSubmit: handleSubmitProfile, control: controlProfile, formState: { errors: profileErrors } } = useForm({
        resolver: yupResolver(profileSchema),
        defaultValues: {
            firstName: "",
            lastName: "",
            mobileNumber: "",
        },
    });

    // Setup form for "Change Password"
    const { handleSubmit: handleSubmitPassword, control: controlPassword, formState: { errors: passwordErrors } } = useForm({
        resolver: yupResolver(passwordSchema),
        defaultValues: {
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
        },
    });

    const onSubmitProfile = (data) => {
        console.log('Profile Data:', data);
        // Handle profile update logic
        displaySnackbar('success', 'Your profile has been changed Successfully');
    };

    const onSubmitPassword = (data) => {
       
        displaySnackbar('success', 'New password is changed Successfully');
    };


    //=============================================================================================

    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleOldPasswordChange = (event) => {
        setOldPassword(event.target.value);
    };

    const handleNewPasswordChange = (event) => {
        setNewPassword(event.target.value);
    };

    const handleConfirmPasswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };

    const handlePasswordUpdate = () => {
        if (newPassword !== confirmPassword) {
            alert('New Password and Confirm Password do not match!');
        } else {
            // Proceed with password update logic
            alert('Password updated successfully!');
        }
    };

    //=============================================================================================

    return (
        <Box>
            <Grid container>
                <Grid item xs={12} md={12}>
                    <Box
                        display="flex"
                        // justifyContent="center"
                        // marginTop="20px"
                        // alignItems="center"
                        height={isMobile ? "auto" : "86vh"}
                        marginTop={2}
                        marginBottom={2}
                    >
                        <Container sx={{
                            display: 'flex',
                            justifyContent: isMobile ? "center" : "flex-start",
                            //  alignItems: 'center',
                            minHeight: '86vh',
                            // height: '100%',
                            // maxWidth: '100%',
                            padding: isMobile ? 1 : 0,
                            minWidth: isMobile ? '100%' : '200vh'
                        }} >
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                //   alignItems: 'center',
                                border: '1px solid',
                                borderColor: 'grey.300',
                                padding: 2,
                                borderRadius: "16px",
                                backgroundColor: '#ffffff',
                                width: "100%",
                                height: "100%",
                            }}>


                                <TabContext value={selectedTab}>
                                    <Box
                                        // sx={{ borderBottom: 1, borderColor: "#0070b2", width: "100%" }}
                                        sx={{ borderBottom: 1, borderColor: 'divider' }}
                                    >

                                        <Typography style={{ fontSize: "27px", fontWeight: 750, fontFamily: 'Arial, Helvetica, sans-serif' }}>
                                            Profile
                                        </Typography>

                                        <TabList onChange={handleTabChange} marginTop={2}>
                                            <Tab label="Update Profile" value="1" sx={{ textTransform: "none", '& .MuiTab-label': { textTransform: 'capitalize' } }} />
                                            <Tab label="Change Password" value="2" sx={{ textTransform: "none", '& .MuiTab-label': { textTransform: 'capitalize' } }} />
                                        </TabList>
                                    </Box>
                                    <TabPanel value="1">
                                        <form onSubmit={handleSubmitProfile(onSubmitProfile)}>
                                            <Box>
                                                <Typography style={{ color: 'gray', fontStyle: 'italic' }}>
                                                    You can change the profile here.
                                                </Typography>
                                                <Grid container
                                                    // xs={12} 
                                                    spacing={isMobile ? 1 : 2}
                                                    marginTop={0.5} style={{ display: "flex", flexDirection: 'row', }}>

                                                    <Grid item xs={8} style={{ display: "flex", flexDirection: "column" }}>
                                                        <Grid item >
                                                            <Controller
                                                                control={controlProfile}
                                                                name='firstName'
                                                                render={({ field }) => (
                                                                    <MuiTextField
                                                                        {...field}
                                                                        name='firstName'
                                                                        size="small"
                                                                        type="text"
                                                                        label="First Name"
                                                                        error={!!profileErrors.firstName}
                                                                        helperText={profileErrors.firstName?.message}
                                                                        style={{
                                                                            width: isMobile ? "100%" : "300px",
                                                                            height: "40px",
                                                                        }}
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    <IconButton edge="end" style={{ pointerEvents: 'none' }}>
                                                                                        <PersonIcon sx={{ fontSize: 20 }} />
                                                                                    </IconButton>
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid item >
                                                            <Controller
                                                                control={controlProfile}
                                                                name='lastName'
                                                                render={({ field }) => (
                                                                    <MuiTextField
                                                                        {...field}
                                                                        name='lastName'
                                                                        size="small"
                                                                        type="text"
                                                                        label="Last Name"
                                                                        error={!!profileErrors.lastName}
                                                                        helperText={profileErrors.lastName?.message}
                                                                        style={{
                                                                            width: isMobile ? "100%" : "300px",
                                                                            height: "40px", marginTop: "10px"
                                                                        }}
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    <IconButton edge="end" style={{ pointerEvents: 'none' }}>
                                                                                        <PersonIcon sx={{ fontSize: 20 }} />
                                                                                    </IconButton>
                                                                                </InputAdornment>
                                                                            ),
                                                                        }} />
                                                                )}
                                                            />
                                                        </Grid>

                                                        <Grid item >
                                                            <MuiTextField
                                                                size="small"
                                                                type="email"
                                                                label="Email"
                                                                style={{
                                                                    width: isMobile ? "100%" : "300px",
                                                                    height: "40px", marginTop: "10px"
                                                                }}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <IconButton edge="end" style={{ pointerEvents: 'none' }}>
                                                                                <EmailIcon sx={{ fontSize: 20 }} />
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                    ),
                                                                }} />
                                                        </Grid>
                                                        <Grid item >
                                                            <Controller
                                                                control={controlProfile}
                                                                name='mobileNumber'
                                                                render={({ field }) => (
                                                                    <MuiTextField
                                                                        {...field}
                                                                        name='mobileNumber'
                                                                        size="small"
                                                                        type="email"
                                                                        label="Phone number"
                                                                        error={!!profileErrors.mobileNumber}
                                                                        helperText={profileErrors.mobileNumber?.message}
                                                                        style={{
                                                                            width: isMobile ? "100%" : "300px",
                                                                            height: "40px", marginTop: "10px"
                                                                        }}
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    <IconButton edge="end" style={{ pointerEvents: 'none' }}>
                                                                                        <PhoneIcon sx={{ fontSize: 20 }} />
                                                                                    </IconButton>
                                                                                </InputAdornment>
                                                                            ),
                                                                        }} />
                                                                )}
                                                            />
                                                        </Grid>
                                                    </Grid>

                                                </Grid>

                                                <Grid container xs={12} marginTop={2} style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <Grid item xs={isMobile ? 12 : 3} style={{ display: 'flex', justifyContent: "center" }}>
                                                        <MUIButton
                                                            title="Update Profile" type="submit" /> </Grid>
                                                    <Grid item xs={4}> </Grid>
                                                </Grid>
                                            </Box></form>
                                        {/* </Container> */}
                                    </TabPanel>
                                    <TabPanel value="2">
                                        <form onSubmit={handleSubmitPassword(onSubmitPassword)}>
                                            <Box>
                                                <Typography style={{ color: 'gray', fontStyle: 'italic' }}>
                                                    You can change the password here.
                                                </Typography>
                                                <Grid container xs={12} marginTop={1.5}>
                                                    <Grid item xs={9} marginTop={3} >
                                                        <Controller
                                                            control={controlPassword}
                                                            name='oldPassword'
                                                            render={({ field }) => (
                                                                <MuiTextField
                                                                    {...field}
                                                                    name='oldPassword'
                                                                    size="small"
                                                                    type={showOldPassword ? 'text' : 'password'}
                                                                    label="Old Password"
                                                                    style={{
                                                                        width: isMobile ? "100%" : "300px",
                                                                        height: "40px",
                                                                    }}
                                                                    error={!!passwordErrors.oldPassword}
                                                                    helperText={passwordErrors.oldPassword?.message}
                                                                    fullWidth
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <IconButton
                                                                                    onClick={() => setShowOldPassword(!showOldPassword)}
                                                                                    edge="end"
                                                                                >
                                                                                    {showOldPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        ),
                                                                    }} />
                                                            )}
                                                        />
                                                        <Controller
                                                            control={controlPassword}
                                                            name='newPassword'
                                                            render={({ field }) => (
                                                                <MuiTextField
                                                                    {...field}
                                                                    name='newPassword'
                                                                    size="small"
                                                                    type={showNewPassword ? 'text' : 'password'}
                                                                    label="New Password"
                                                                    style={{
                                                                        width: isMobile ? "100%" : "300px",
                                                                        height: "40px", marginTop: "10px"
                                                                    }}
                                                                    error={!!passwordErrors.newPassword}
                                                                    helperText={passwordErrors.newPassword?.message}
                                                                    fullWidth
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <IconButton
                                                                                    onClick={() => setShowNewPassword(!showNewPassword)}
                                                                                    edge="end"
                                                                                >
                                                                                    {showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        ),
                                                                    }} />
                                                            )}
                                                        />
                                                        <Controller
                                                            control={controlPassword}
                                                            name='confirmPassword'
                                                            render={({ field }) => (
                                                                <MuiTextField
                                                                    {...field}
                                                                    name='confirmPassword'
                                                                    size="small"
                                                                    type={showConfirmPassword ? 'text' : 'password'}
                                                                    label="Confirm Password"
                                                                    style={{
                                                                        width: isMobile ? "100%" : "300px",
                                                                        height: "40px", marginTop: "10px"
                                                                    }}
                                                                    error={!!passwordErrors.confirmPassword}
                                                                    helperText={passwordErrors.confirmPassword?.message}
                                                                    fullWidth
                                                                    InputProps={{
                                                                        endAdornment: (
                                                                            <InputAdornment position="end">
                                                                                <IconButton
                                                                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                                                    edge="end"
                                                                                >
                                                                                    {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                        ),
                                                                    }} />
                                                            )}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={3}></Grid>
                                                </Grid>

                                                <Grid container xs={12} marginTop={1.5} style={{ display: 'flex', flexDirection: 'row' }}>
                                                    {/* <Grid item xs={1}> </Grid> */}
                                                    <Grid item xs={isMobile ? 12 : 3} style={{ display: 'flex', justifyContent: "center" }}>
                                                        <MUIButton
                                                            title="Update Password"
                                                            type="submit"
                                                        // onClick={handlePasswordUpdate} 
                                                        /> </Grid>
                                                    <Grid item xs={4}> </Grid>
                                                </Grid>
                                            </Box></form>

                                    </TabPanel>
                                </TabContext>
                            </Box>
                        </Container>
                    </Box>
                </Grid>
            </Grid>
        </Box>

    )
}

export default Profile
