import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DescriptionIcon from '@mui/icons-material/Description';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Container, createTheme, Grid, ListItemIcon, MenuItem, Select, Typography, } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate } from 'react-router-dom';


const theme = createTheme();

const TextEditer = () => {
    const navigator = useNavigate();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


    const handleSubmit = (event) => {
        event.preventDefault();
        // handle form submission
    };

    //=======================================================================
    const [content, setContent] = useState('');
    const handleContentChange = (value) => {
        setContent(value);
        console.log("content", content);

    };
    useEffect(() => {
        const editor = document.querySelector('.ql-editor');
        if (editor) {
            editor.setAttribute('spellcheck', 'false');
        }
    }, []);

    const handleSave = () => {
        navigator('/documentList')
    }

    // const handleCopy = () => {
    //     if (navigator.clipboard && navigator.clipboard.writeText) {
    //         navigator.clipboard.writeText(content)
    //             .then(() => {
    //                 console.log('Content copied to clipboard');
    //             })
    //             .catch(err => {
    //                 console.error('Failed to copy content: ', err);
    //             });
    //     } else {
    //         console.warn('Clipboard API not supported or permissions issue');
    //         // Fallback: Select the content and prompt the user to copy manually
    //         const textArea = document.createElement('textarea');
    //         textArea.value = content;
    //         document.body.appendChild(textArea);
    //         textArea.select();
    //         try {
    //             document.execCommand('copy');
    //             console.log('Content copied using execCommand');
    //         } catch (err) {
    //             console.error('Fallback: Could not copy text: ', err);
    //         }
    //         document.body.removeChild(textArea);
    //     }
    // };



    const handleCopy = () => {
        // Extract plain text content from the ReactQuill editor
        const plainTextContent = new DOMParser().parseFromString(content, 'text/html').body.textContent || '';

        if (navigator.clipboard && navigator.clipboard.write) {
            const blob = new Blob([plainTextContent], { type: 'text/plain' });
            const clipboardItem = new ClipboardItem({ 'text/plain': blob });

            navigator.clipboard.write([clipboardItem])
                .then(() => {
                    console.log('Content copied to clipboard');
                })
                .catch(err => {
                    console.error('Failed to copy content: ', err);
                });
        } else {
            console.warn('Clipboard API not supported or permissions issue');
            // Fallback: Select the content and prompt the user to copy manually
            const textArea = document.createElement('textarea');
            textArea.value = plainTextContent;
            document.body.appendChild(textArea);
            textArea.select();
            try {
                document.execCommand('copy');
                console.log('Content copied using execCommand');
            } catch (err) {
                console.error('Fallback: Could not copy text: ', err);
            }
            document.body.removeChild(textArea);
        }
    };


    const toolbarOptions = [
        [{ 'header': [1, 2, 3, false] }, { 'font': [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'], 
        [{ 'color': [] }, { 'background': [] }], 
        [{ 'list': 'ordered'}, { 'list': 'bullet' }], 
        [{ 'script': 'sub'}, { 'script': 'super' }], 
        [{ 'indent': '-1'}, { 'indent': '+1' }], 
        [{ 'align': [] }], 
        ['link', 'image'], 
        ['clean'],
      ];
    
      const modules = {
        toolbar: toolbarOptions,
      };

    const formats = [
        'header','font', 'bold', 'italic', 'underline', 'strike','blockquote', 'color', 'background',
        'list', 'bullet', 'indent', 'align', 'script', 'link', 'image','size',
      ];

    //=======================================================================

    return (
        <Box>
            <Grid container>
                <Grid item xs={12} md={12}>
                    <div style={{ background: 'linear-gradient(135deg, #e0f7fa, #fff)' }}>
                        <Box sx={{ padding: isMobile ? 1 : 2 }}>
                            <Grid marginTop={isMobile ? 0.5 : 1}>
                                <Typography className='imageUploadHeader1'>Text<span style={{ color: "#0aa2e0", paddingLeft: "10px" }}>Converter</span></Typography>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        marginTop: isMobile ? "8px" : "12px",
                                        fontFamily: "Georgia, serif",
                                        //  textAlign: "center",
                                        color: "#6B7280",
                                        fontSize: isMobile ? '12px' : '16px',
                                    }}
                                >
                                    Use this image to text converter to extract and copy text from an image in a single click.
                                </Typography>
                                <Typography style={{ display: "flex", justifyContent: "center", fontSize: '16px' }}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        marginTop: "4px",
                                        fontFamily: "Georgia, serif",
                                        textAlign: "center",
                                        color: "#6B7280",
                                        fontSize: isMobile ? '12px' : '16px'
                                    }}>---- file name ----</Typography></Grid>
                            <Box width="100%" height="100%">
                                <Container sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    // alignItems: 'center',
                                    minHeight: '75vh',
                                    // height: '100%',
                                    // maxWidth: '100%',
                                    padding: 0,
                                    minWidth: isMobile ? '100%' : '200vh',
                                    marginTop: "10px"
                                }}>
                                    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            // alignItems: 'center',
                                            border: '1px solid',
                                            borderColor: 'grey.300',
                                            padding: isMobile ? 1 : 2,
                                            borderRadius: "16px",
                                            backgroundColor: '#ffffff',
                                            width: "100%",
                                            height: "90%",
                                        }}>

                                            <Grid container>
                                                {isMobile ? (
                                                    <>
                                                        <Grid item xs={6}>
                                                            <Box sx={{ display: "flex", justifyContent: "space-evenly", width: "100%" }}>
                                                                <Box sx={{
                                                                    width: 40,
                                                                    height: 35,
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    border: '1px solid #ccc',
                                                                    borderRadius: '4px',
                                                                    backgroundColor: '#f5f5f5',
                                                                }}>
                                                                    <Tooltip title="Copy Result" arrow placement="top">
                                                                        <ContentCopyIcon
                                                                            sx={{ fontSize: 18, cursor: "pointer" }}
                                                                            onClick={handleCopy}
                                                                        />
                                                                    </Tooltip>
                                                                </Box>
                                                                <Box sx={{
                                                                    width: 40,
                                                                    height: 35,
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    border: '1px solid #ccc',
                                                                    borderRadius: '4px',
                                                                    backgroundColor: '#f5f5f5',
                                                                }}>
                                                                    <Tooltip title="Save Document" arrow placement="top">
                                                                        <SaveIcon sx={{ fontSize: 18, cursor: "pointer" }} onClick={handleSave} />
                                                                    </Tooltip>
                                                                </Box>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Select
                                                                value=""
                                                                displayEmpty
                                                                sx={{
                                                                    width: 120,
                                                                    height: 35,
                                                                    border: '1px solid #ccc',
                                                                    borderRadius: '4px',
                                                                    backgroundColor: '#f5f5f5',
                                                                    '& .MuiSelect-select': {
                                                                        padding: '8px 16px',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                    },
                                                                    '& .MuiSelect-icon': {
                                                                        color: '#000',
                                                                    },
                                                                    '& .MuiMenu-paper': {
                                                                        width: 200,
                                                                        border: '1px solid #ccc',
                                                                        borderRadius: '4px',
                                                                        backgroundColor: '#f5f5f5',
                                                                    },
                                                                }}
                                                            >
                                                                <MenuItem value="" disabled sx={{ fontWeight: 'bold' }}>
                                                                    Export As
                                                                </MenuItem>
                                                                <MenuItem value={10} sx={{ borderBottom: '1px solid #ddd' }}>
                                                                    <ListItemIcon>
                                                                        <PictureAsPdfIcon sx={{ color: 'red' }} fontSize="small" />
                                                                    </ListItemIcon>
                                                                    <Typography variant="inherit">Pdf File (pdf)</Typography>
                                                                </MenuItem>
                                                                <MenuItem value={20}>
                                                                    <ListItemIcon>
                                                                        <DescriptionIcon sx={{ color: 'blue' }} fontSize="small" />
                                                                    </ListItemIcon>
                                                                    <Typography variant="inherit">Word File (doc)</Typography>
                                                                </MenuItem>
                                                            </Select>
                                                        </Grid></>
                                                ) : (
                                                    <>
                                                        <Grid item xs={4}></Grid>
                                                        <Grid item xs={4}></Grid>
                                                        <Grid item xs={4} style={{ display: "flex", flexDirection: "row", justifyContent: 'space-evenly' }}>
                                                            <Box sx={{
                                                                width: 50,
                                                                height: 40,
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                border: '1px solid #ccc',
                                                                borderRadius: '4px',
                                                                backgroundColor: '#f5f5f5',
                                                            }}
                                                            >
                                                                <Tooltip placement='top' title="Copy Result">
                                                                    <ContentCopyIcon sx={{ fontSize: 20, cursor: "pointer" }} onClick={handleCopy} /></Tooltip>
                                                            </Box>
                                                            <Box sx={{
                                                                width: 50,
                                                                height: 40,
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                border: '1px solid #ccc',
                                                                borderRadius: '4px',
                                                                backgroundColor: '#f5f5f5',
                                                            }}>
                                                                <Tooltip placement='top' title="Save Document">
                                                                    <SaveIcon sx={{ fontSize: 20, cursor: "pointer" }} onClick={handleSave} /></Tooltip>
                                                            </Box>
                                                            <Select
                                                                value=""
                                                                displayEmpty
                                                                sx={{
                                                                    width: 150,
                                                                    height: 40,
                                                                    border: '1px solid #ccc',
                                                                    borderRadius: '4px',
                                                                    backgroundColor: '#f5f5f5',
                                                                    '& .MuiSelect-select': {
                                                                        padding: '8px 16px',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                    },
                                                                    '& .MuiSelect-icon': {
                                                                        color: '#000',
                                                                    },
                                                                    '& .MuiMenu-paper': {
                                                                        width: 200,
                                                                        border: '1px solid #ccc',
                                                                        borderRadius: '4px',
                                                                        backgroundColor: '#f5f5f5',
                                                                    },
                                                                }}
                                                            >
                                                                <MenuItem value="" disabled sx={{ fontWeight: 'bold' }}>
                                                                    Export As
                                                                </MenuItem>
                                                                <MenuItem value={10} sx={{ borderBottom: '1px solid #ddd' }}>
                                                                    <ListItemIcon>
                                                                        <PictureAsPdfIcon sx={{ color: 'red' }} fontSize="small" />
                                                                    </ListItemIcon>
                                                                    <Typography variant="inherit">Pdf File (pdf)</Typography>
                                                                </MenuItem>
                                                                <MenuItem value={20} >
                                                                    <ListItemIcon>
                                                                        <DescriptionIcon sx={{ color: 'blue' }} fontSize="small" />
                                                                    </ListItemIcon>
                                                                    <Typography variant="inherit">Word File (doc)</Typography>
                                                                </MenuItem>
                                                            </Select>
                                                        </Grid>
                                                    </>
                                                )}


                                            </Grid>

                                            <Grid marginTop={2}>
        
                                                <ReactQuill
                                                    value={content}
                                                    onChange={handleContentChange}
                                                    modules={modules}
                                                    formats={formats}
                                                    style={{
                                                        height: isMobile ? '400px' : '250px',
                                                        marginBottom: '20px'
                                                    }}
                                                />
                                            </Grid>


                                        </Box>
                                    </form>


                                </Container>
                            </Box></Box ></div>
                </Grid>
            </Grid>
        </Box >

    )
}

export default TextEditer
