import "./imageUpload.css"
import { Box, Button, Card, Grid, TextField, Typography, useMediaQuery, useTheme } from "@mui/material"
import PermMediaOutlinedIcon from '@mui/icons-material/PermMediaOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { Modal, Progress, Spin, Upload, message } from 'antd';
import MUIButton from "../../reusable_components/MuiSaveButton";
import { useRef, useState } from "react";
import ImgCrop from 'antd-img-crop';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import BackupIcon from '@mui/icons-material/Backup';
import CircularIndeterminate from "../loader/loader";
import { useNavigate } from "react-router-dom";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import folder from "../../assets/FolderPic.png"

const ImageUpload = () => {

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));  

    const { Dragger } = Upload;

    const [progress, setProgress] = useState(0)
    const [loading, setLoading] = useState(false)
    const [progressMessage, setProgressMessage] = useState(false)
    const [loader, setLoader] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [fileView, setFileView] = useState("")
    const [crop, setCrop] = useState({ aspect: 16 / 9 })
    const imageRef = useRef(null)
    const [croppedImage, setCroppedImage] = useState(null)
    const [fileName, setFileName] = useState("")
    const [fileType, setFileType] = useState(false)
    const [boxDelete, setBoxDelete] = useState(false)

    const props = {
        name: "File",
        // action: "",
        listType: "picture",
        multiple: false,
        maxCount: 1,
        showUploadList: true,
        customRequest: ({ file, onSuccess }) => {
            setBoxDelete(false)
            setFileView(URL.createObjectURL(file));
            setLoading(true)
            setFileName(file?.name)
            if(file?.type.startsWith("image/")){
                setFileType(true)
            } else {
                setFileType(false)
            }
            
            setProgress(0)
            const simulateUpload = (file) => {
                const interval = setInterval(() => {
                    setProgress((prevProgress) => {
                        if (prevProgress === 100) {
                            clearInterval(interval)
                            // setFile(URL.createObjectURL(file));
                            onSuccess("ok")
                            setProgressMessage(true)
                            return 100
                        }
                        return prevProgress + 10
                    })
                }, 100);
            }
            simulateUpload(file)
        },
        beforeUpload: (file) => {
            console.log("file", file)
            const isImage = file?.type?.startsWith("image/") || file?.type === 'application/pdf' || file?.type.startsWith("application/vnd");
            if (!isImage) {
                message.error(`${file?.name} is not an supported file format`)
                setLoading(false)
                return false
            }
            return isImage
        },
        onChange: (info) => {
            const { status } = info?.file
            if (status === "done") {
                message.success(`${info?.file?.name} file uploaded successfully`)
            } else {
                if (status === "error") {
                    message.error(`${info?.file?.name} file upload failed`)
                }
            }
            setProgressMessage("")
        },
        accept: "image/*,.pdf,.doc,.docx,.xls,.xlsx",
        iconRender: (file) => {
            if (file?.type?.startsWith("image/"))
                return <Spin></Spin>
        },
        onRemove: () => {
            setLoading(false)
        },
        itemRender(originNode, file, fileList, actions) {
            console.log("originNode", originNode + "file", file + "fileList", fileList + "Actions", actions);

            return (
                <div style={{ display: 'flex', alignItems: 'center', width: "100%", flexWrap: "wrap" }}>
                    <div style={{ display: "flex", flexDirection: isMediumScreen ? "row" : "column", alignItems: isMediumScreen ? "center" : "flex-start", width: "100%" }}>
                        {!boxDelete && (
                        <Box
                            sx={{
                                border: "1px solid #0070b2",
                                padding: "5px",
                                marginTop: "15px"
                            }}
                        >
                            <div style={{ position: "relative" }}>
                                {fileType ? (
                                <img
                                    src={croppedImage ? croppedImage : fileView}
                                    alt="Cropped Image"
                                    style={{ marginTop: "20px", width: isMediumScreen ? "150px" : "80px", height: isMediumScreen ? "100px" : "50px" }}
                                />
                            ) : (
                                <img
                                src={folder}
                                alt="file"
                                style={{width: "120px", height: "100px" }}
                            />
                            )}
                                {fileView && fileType && (
                                    <Button
                                        size="small"
                                        onClick={openImageDialog}
                                        sx={{
                                            position: "absolute",
                                            right: 0,
                                            backgroundColor: "#0aa2e0",
                                            fontFamily: "inherit",
                                            textTransform: "none",
                                            color: "#fff",
                                            borderRadius: "20px",
                                            minWidth: isMediumScreen ? "50px" : "30px",
                                            height: isMediumScreen ? "30px" : "25px",
                                            "&:hover": {
                                                backgroundColor: "#0070b2"
                                            }
                                        }}
                                    >
                                        Crop
                                    </Button>
                                )}
                            </div>
                        </Box>
                        )}
                        <div style={{ width: "100%", marginLeft: isMediumScreen ? "10px" : 0 }}>
                            {loading &&
                                <>
                                <div style={{display: "flex", flexDirection: "row", alignItems: "center" }}>
                                 <Typography sx={{ position: "absolute", marginTop: isMediumScreen ? "5px" : "1px", textWrap: "nowrap", fontSize: isMediumScreen ? "16px" : "11px" }}>{fileName}</Typography>
                                    <Progress percent={progress}
                                        strokeColor={{
                                            '0%': '#0aa2e0',
                                            '100%': '#64d1fe',
                                        }}
                                        trailColor="#f3f4f6"
                                        strokeWidth={4}
                                        style={{ marginTop: isMediumScreen ? "30px" : "15px", marginRight: isMediumScreen ? "10px" : 0 }}
                                    />
                                    <DeleteOutlineOutlinedIcon sx={{color: "red", cursor: "pointer", marginTop: isMediumScreen ? "30px" : "15px", fontSize: isSmallScreen ? "19px" : "25px", marginLeft: "5px" }}
                                        onClick={handleDeleteFile}
                                    />
                                    </div>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        {progressMessage === true ? (
                                            <>
                                                <CloudDoneIcon sx={{ color: "#32CD32", marginRight: isMediumScreen ? '10px' : '5px', fontSize: isSmallScreen ? "19px" : "24.5px" }} />
                                                <Typography sx={{ color: "#32CD32", fontWeight: 600, fontSize: isMediumScreen ? "14px" : "9px" }}>
                                                    Uploaded Successfully!
                                                </Typography>
                                            </>
                                        ) : (
                                            <>
                                                <BackupIcon sx={{ color: "#0aa2e0", marginRight: '10px' }} />
                                                <Typography sx={{ color: "#0aa2e0", fontWeight: 600, fontSize: isMediumScreen ? "14px" : "9px" }}>
                                                    Uploading... Please wait
                                                </Typography>
                                            </>
                                        )}                  

                                    </Box>
                                </>
                            }
                        </div>
                    </div>
                </div>
            );
        }
    }

    const handleDeleteFile = () => {
        setLoading(false)
        setFileView("")
        setBoxDelete(true)
    }
    
    // const beforeCrop = (file) => {
    //     const isImage = file?.type?.startsWith("image/");
    //     if (!isImage) {
    //         return false;
    //     }
    //     return true;
    // };

    const navigate = useNavigate()

    const handleLoader = () => {
        setLoader(true);
        setTimeout(() => {
            setLoader(false);
            navigate(`/textEditer`)
        }, 6000);
    };

    const onImageLoaded = (image) => {
        imageRef.current = image
    }

    const getCroppedImg = async () => {
        const canvas = document.createElement("canvas")
        const scaleX = imageRef.current.naturalWidth / imageRef.current.width
        const scaleY = imageRef.current.naturalHeight / imageRef.current.height

        canvas.width = crop.width * scaleX;
        canvas.height = crop.height * scaleY;

        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            imageRef.current,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );

        return new Promise((resolve) => {
            canvas.toBlob(
                (blob) => {
                    if (blob) {
                        const croppedImageUrl = URL.createObjectURL(blob);
                        resolve(croppedImageUrl);
                    }
                },
                "image/jpeg",
                1
            );
        });
    }

    const handleCropConfirm = async () => {
        if (imageRef.current && crop.width && crop.height) {
            const croppedUrl = await getCroppedImg()
            setCroppedImage(croppedUrl)
            setIsModalOpen(false)
        }
    }

    const handleModalCancel = () => {
        setIsModalOpen(false)
    }

    const openImageDialog = () => {
        setIsModalOpen(true)
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'linear-gradient(135deg, #e0f7fa, #fff)', minHeight: "90vh", padding: "20px" }}>
            {loader ? <CircularIndeterminate /> : ""}
            <Box sx={{ maxWidth: "80%", textAlign: "center" }}>
                <Grid container spacing={4} sx={{ justifyContent: "center" }}>
                    <Grid item xs={12}>
                        <Typography
                            variant="h4"
                            className={isMediumScreen ? "imageUploadHeader" : "imageUploadHeaderForMobile"}
                        >
                            Convert your Files into
                            <span style={{color: "#0aa2e0", marginLeft: "8px"}}>Text</span>
                        </Typography>
                        <Typography
                            variant="body1"
                            sx={{
                                marginTop: "10px",
                                fontFamily: "Georgia, serif",
                                textAlign: "center",
                                color: "#6B7280",
                                fontSize: isMediumScreen ? "15px" : "12px"
                            }}
                        >
                            {isMediumScreen ? (
                           "Upload your Image or files and Extract your Editable text from the Image \n with a Single Click"
                            ) : (
                                "Upload your Image or files and Extract your Editable text"
                            )}
                        </Typography>
                    </Grid>

                    <Grid container sx={{ display: "flex", flexDirection: "column", alignItems: "center", marginLeft: "30px" }}>
                        <Grid item xs={12} md={9}>
                            <Box className="parentBoxImageUpload">
                                {/* <ImgCrop
                        rotate
                        showGrid
                        rotationSlider
                        aspectSlider
                        showReset
                        modalTitle="Crop your image"
                        modalOk="Crop"
                        modalCancel="Cancel"
                        beforeCrop={beforeCrop}
                        modalProps={{
                            wrapClassName: 'custom-img-crop-modal',
                            maskStyle: { backgroundColor: 'rgba(0, 0, 0, 0.8)' },
                            bodyStyle: { backgroundColor: '#ffffff', borderRadius: '8px' },
                            footerStyle: { textAlign: 'center' }
                        }}
                    > */}
                                <Dragger {...props} style={{ backgroundColor: "#f3f4f6", borderRadius: "10px", padding: "10px", border: "1px dashed #0aa2e0", display: "flex", justifyContent: "Center" }}>
                                    <CloudUploadOutlinedIcon sx={{ fontSize: isSmallScreen ? "50px" : "80px", marginBottom: "10px", color: "#0aa2e0" }} />
                                    <Typography sx={{ fontWeight: "bold", color: "#0aa2e0", fontSize: isMediumScreen ? "16px" : "10px" }}>
                                        Click or drag file to this area to upload
                                    </Typography>
                                    <Typography sx={{ color: "#6B7280", marginTop: "10px", fontSize: isMediumScreen ? "16px" : "10px" }}>
                                        Upload your photo, to get text file instantly. Our tool will not take more than a minute to convert an image to text.
                                    </Typography>
                                    <MUIButton title="Browse Files" startIcon={<PermMediaOutlinedIcon />}
                                        sx={{
                                            marginTop: "20px",
                                            minWidth: isMediumScreen ? "140px" : "60px",
                                            height: isMediumScreen ? "45px" : "30px",
                                            borderRadius: "25px",
                                            color: "#fff",
                                            fontSize: isMediumScreen ? "14px" : "10px",
                                            backgroundColor: "#0aa2e0",
                                            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                                            background: "linear-gradient(to right, #64d1fe, #0aa2e0)",

                                            "&:hover": {
                                                backgroundColor: "#64d1fe",
                                                background: "linear-gradient(to right, #0aa2e0, #64d1fe)",
                                                transform: "scale(1.05)",
                                                boxShadow: "0px 6px 10px rgba(0, 0, 0, 0.15)"
                                            }
                                        }}
                                    />
                                </Dragger>

                                <Modal
                                    title="Crop your Image"
                                    closable={false}
                                    open={isModalOpen}
                                    footer={null}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}
                                >
                                    <Box
                                        sx={{
                                            maxWidth: "600px",
                                            margin: "15px",
                                            bgcolor: "#e0f7fa",
                                            borderRadius: "8px",
                                            boxShadow: 24,
                                            p: 3
                                        }}
                                    >
                                        <Typography variant="body2" sx={{ mb: 2 }}>
                                            Adjust your crop area and click "crop" to apply your changes
                                        </Typography>
                                        <Grid container>
                                            <Grid
                                                item
                                                xs={12}
                                            >
                                                {fileView && (
                                                    <div style={{ display: 'flex', justifyContent: "center" }}>
                                                        <ReactCrop
                                                            crop={crop}
                                                            onChange={(newCrop) => setCrop(newCrop)}
                                                            onImageLoaded={onImageLoaded}
                                                        // style={{ maxWidth: "100%", height: "auto" }}
                                                        >
                                                            <img
                                                                ref={imageRef}
                                                                src={fileView}
                                                                alt="crop image"
                                                                style={{ maxWidth: "100%", maxHeight: "100%" }}
                                                            />
                                                        </ReactCrop>
                                                    </div>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Button
                                        onClick={handleCropConfirm}
                                        variant="contained"
                                        color="primary"
                                        sx={{ mt: 2, width: "100%" }}
                                    >
                                        Crop
                                    </Button>
                                    <Button
                                        onClick={handleModalCancel}
                                        variant="outlined"
                                        color="primary"
                                        sx={{ mt: 1, width: "100%" }}
                                    >
                                        Cancel
                                    </Button>
                                </Modal>
                                {/* {croppedImage && (
                                <img 
                                    src={croppedImage}
                                    alt="Cropped Image"
                                    style={{ marginTop: "20px", maxWidth: "175px", height: "135px" }}
                                />
                               )}        */}

                                {/* </ImgCrop> */}
                                {/* {loading &&
                                    <>
                                        <Progress percent={progress}
                                            strokeColor={{
                                                '0%': '#0aa2e0',
                                                '100%': '#64d1fe',
                                            }}
                                            trailColor="#f3f4f6"
                                            strokeWidth={4}
                                            style={{ marginTop: "20px" }}
                                        />
                                        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "10px" }}>
                                            {progressMessage === true ? (
                                                <>
                                                    <CloudDoneIcon sx={{ color: "#32CD32", marginRight: '10px' }} />
                                                    <Typography sx={{ color: "#32CD32", fontWeight: 600 }}>
                                                        Uploaded Successfully!
                                                    </Typography>
                                                </>
                                            ) : (
                                                <>
                                                    <BackupIcon sx={{ color: "#0aa2e0", marginRight: '10px' }} />
                                                    <Typography sx={{ color: "#0aa2e0", fontWeight: 600 }}>
                                                        Uploading... Please wait
                                                    </Typography>
                                                </>
                                            )}
                                        </Box>
                                    </>
                                } */}
                                {progressMessage === true && !boxDelete && (
                                    <Button
                                        size="small"
                                        onClick={handleLoader}
                                        sx={{
                                            fontFamily: "inherit",
                                            textTransform: "none",
                                            backgroundColor: "#0aa2e0",
                                            color: "white",
                                            fontSize :isMediumScreen ? "13px" : "8px",
                                            width: isMediumScreen ? "120px" : "70px",
                                            marginTop: isSmallScreen && "10px",
                                            "&:hover": {
                                                backgroundColor: "#0072b0"
                                            }
                                        }}
                                    >
                                        Save & Extract
                                    </Button>
                                )}

                            </Box>
                        </Grid>

                        <Grid item >
                            {/*  */}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}

export default ImageUpload