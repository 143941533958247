import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import './loader.css'
import { Typography } from '@mui/material';

export default function CircularIndeterminate() {
  return (
    <Box sx={{ display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: "center",
        position: 'fixed',
        top: '0px',
        width: '100%',
        height: '100%',
        zIndex: '9999',
        backgroundColor: "rgba(0,0,0,0.8)"
     }}>
      <CircularProgress className='loader' size={60}/>
      <Typography sx={{ color: 'white', marginTop: '20px', fontWeight: "bold" }}>Extracting... Please wait</Typography>
    </Box>
  );
}