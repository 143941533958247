import React, { createContext, useState } from 'react'

export const SnackbarContext = createContext()

export const SnackbarProvider = ({ children }) => {
  const [open, setOpen] = useState(false)
  const [severity, setSeverity] = useState('')
  const [message, setMessage] = useState('')
  const [title, setTitle] = useState('')

  const displaySnackbar = (newSeverity, newTitle, newMessage) => {
    setOpen(true)
    setSeverity(newSeverity)
    setTitle(newTitle)
    setMessage(newMessage)

    // Automatically close snackbar after 5 seconds
    setTimeout(() => {
      setOpen(false)
    }, 5000)
  }

  const closeSnackbar = () => {
    setOpen(false)
  }

  return (
    <SnackbarContext.Provider
      value={{ open, severity, title, message, displaySnackbar, closeSnackbar }}
    >
      {children}
    </SnackbarContext.Provider>
  )
}
