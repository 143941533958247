import React, { useContext } from 'react'
import { Snackbar, Alert, Typography } from '@mui/material'
import { SnackbarContext } from './Snackbar'

const AlertStack = () => {
  const { open, setOpen, severity, title, message, closeSnackbar } = useContext(SnackbarContext)

  return (
    <Snackbar
      open={open}
      autoHideDuration={2000}
      onClose={closeSnackbar}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
    >
      <Alert severity={severity || 'info'} onClose={closeSnackbar}>
      <Typography sx={{ fontSize: "16px" }}> <strong>{title}</strong>
       {message}</Typography>
      </Alert>
    </Snackbar>
  )
}

export default AlertStack
