import { LogoutOutlined } from '@ant-design/icons';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import PersonIcon from '@mui/icons-material/Person';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import { Box, Button, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Layout, Menu } from 'antd';
import { useContext, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import '../App.css'
import DocumentList from '../components/documentListPage/DocumentList'
import Home from '../components/homePage/Home'
import ImageUpload from '../components/imageUpload/ImageUpload';
import Forgotpassword from '../components/loginPage/Forgotpassword';
import Login from '../components/loginPage/Login';
import SignUp from '../components/signUpPage/SignUp';
import TextEditer from '../components/textEditer/TextEditer';
import Profile from '../components/userProfile/Profile';
import { SnackbarContext } from '../reusable_components/Snackbar';


const AuthenticatedRoutes = () => {

    const theme = useTheme();
    const { displaySnackbar } = useContext(SnackbarContext)
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  
    const { Header, Content, Footer } = Layout;
  
    const navigate = useNavigate()
  
    const handleLoginNavigation = () => {
      navigate(`/login`)
    }
  
    const handleSignUpnavigation = () => {
      navigate(`/signUp`)
    }
  
    const location = useLocation()
    console.log("locaton", location);
  
  
    useEffect(() => {
      if (location.pathname === "/login" || location.pathname === "/signUp" || location.pathname === "/fordotPassword") {
      }
    }, [location.pathname])
  
    const d = new Date();
    let year = d.getFullYear();
  
    const shouldDisplayProfile = location.pathname === '/imageUpload' || location.pathname === '/textEditer' || location.pathname === '/profile' || location.pathname === '/documentList';
  
    const handleProfileNavigation = () => {
      navigate(`/profile`)
    }
  
    const handleListNavigation = () => {
      navigate(`/documentList`)
    }
  
    const handleLogOutNavigation = () => {
      displaySnackbar('success', 'LogOut Successfully');
      navigate(`/login`)
    }

  return (
      <Box className="app-wrapper">
      <Layout className='content'>
        <Header
          style={{
            display: 'flex',
            position: "sticky",
            top: 0,
            zIndex: 100,
            justifyContent: "space-between"
          }}

        >
          <Box
            sx={{ display: "flex", alignItems: "center" }}
          >
            <DocumentScannerIcon
              sx={{
                fontSize: { xs: "29px", md: "40px" },
                color: "#0aa2e0",
                marginRight: 0.5
              }}
            />
            <Typography
              sx={{
                fontWeight: 800,
                fontSize: { xs: "13px", md: "20px" },
                fontFamily: "Tahoma, sans-serif"
              }}
            > TEX<span style={{ color: "#0aa2e0" }}>APP</span></Typography>
          </Box>

          <Menu mode='horizontal' style={{ backgroundColor: "#fff" }} className='menu'>
            {shouldDisplayProfile && (
              <>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "20px", marginRight: isMediumScreen && "15px" }}>
                  <Tooltip title="Profile" arrow>
                    <PermIdentityIcon onClick={handleProfileNavigation} sx={{ fontSize: isSmallScreen ? "23px" : "30px", color: "#0aa2e0", cursor: "pointer" }} />
                  </Tooltip>
                  <Tooltip title="Document List" arrow>
                    <TextSnippetOutlinedIcon onClick={handleListNavigation} style={{ fontSize: isSmallScreen ? "20px" : "25px", color: "#0aa2e0", cursor: "pointer" }} />
                  </Tooltip>
                  <Tooltip title="Log out" arrow>
                    <LogoutOutlined onClick={handleLogOutNavigation} style={{ fontSize: isSmallScreen ? "18px" : "23px", color: "#0aa2e0", cursor: "pointer" }} />
                  </Tooltip>
                </div>
              </>
            )}
            {location.pathname === "/" && (
              <>
              
                <Button startIcon={<PersonIcon sx={{ fontSize: { xs: "10px", md: "14px" }, marginRight: { xs: "-7px", md: "0px" }, }} />}
                  onClick={handleLoginNavigation}
                  sx={{
                    display: "flex",
                    fontFamily: "inherit",
                    textTransform: "none",
                    backgroundColor: "white",
                    borderRadius: "20px",
                    width: { xs: "50px", md: "100px" },
                    color: "#0aa2e0",
                    border: "1px solid #564345",
                    fontSize: { xs: "10px", md: "14px" },
                    marginRight: "15px",
                    height: { xs: "30px", md: "40px" },
                    "&:hover": {
                      color: "white",
                      backgroundColor: "#0aa2e0",
                      border: "1px solid white",
                      transition: "ease-in-out"
                    }
                  }}
                >Login</Button>
                <Button
                  onClick={handleSignUpnavigation}
                  sx={{
                    display: "flex",
                    fontFamily: "inherit",
                    textTransform: "none",
                    backgroundColor: "#0aa2e0",
                    width: { xs: "50px", md: "100px" },
                    borderRadius: "20px",
                    border: "1px solid white",
                    color: "white",
                    height: { xs: "30px", md: "40px" },
                    fontSize: { xs: "10px", md: "14px" },
                    "&:hover": {
                      color: "#0aa2e0",
                      border: "1px solid #000",
                      transition: "ease-in-out",
                      backgroundColor: "white"
                    }
                  }}
                >Sign Up</Button>
              </>
            )}
          </Menu>
        </Header>

        <Content>
          <Routes>
            <Route path="" element={<Home />} />
            <Route path="/imageUpload" element={<ImageUpload />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signUp" element={<SignUp />} />
            <Route path="/forgotPassword" element={<Forgotpassword />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/textEditer" element={<TextEditer />} />
            <Route path="/documentList" element={<DocumentList />} />
          </Routes>
        </Content>
        <Footer className="footer" style={{ textAlign: 'center', fontSize: isSmallScreen ? "10px" : "14px" }}> Copyright &copy; {year} TexApp, All Rights Reserved. </Footer>
      </Layout>
    </Box>
  )
}

export default AuthenticatedRoutes
