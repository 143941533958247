import React, { useContext, useEffect, useState } from 'react';
import { Container, Typography, Box, Grid, Button, Divider, InputAdornment, IconButton, createTheme } from '@mui/material';
import MuiTextField from '../../reusable_components/MuiTextField';
import MUIButton from '../../reusable_components/MuiSaveButton';
import { GoogleLogin } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import AppleLogin from 'react-apple-login';
import { styled } from '@mui/system';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import AppleIcon from '@mui/icons-material/Apple';
import { useNavigate } from 'react-router-dom';
import LoginImg from '../../assets/Login2.png';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import EmailIcon from '@mui/icons-material/Email';
import useMediaQuery from '@mui/material/useMediaQuery';
import { SnackbarContext } from '../../reusable_components/Snackbar';

const theme = createTheme();

const CustomButton = styled(Button)({
  backgroundColor: '#4285F4', // Google Blue color
  color: '#fff',
  textTransform: 'none',
  borderRadius: 4,
  padding: '10px 20px',
  // borderRadius: '20px',
  marginBottom: 10,
  '&:hover': {
    backgroundColor: '#357ae8',
  },
});

const Login = () => {
  const navigate = useNavigate();
  const { displaySnackbar } = useContext(SnackbarContext)
  const [showPassword, setShowPassword] = useState(false);

  // const isMobile = useMediaQuery('(max-width:600px)');
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isTabletPortrait = useMediaQuery('(orientation: portrait) and (min-width:600px) and (max-width:960px)');
  const isTabletLandscape = useMediaQuery('(orientation: landscape) and (min-width:960px) and (max-width:1280px)');


  const validationLogin = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required'),
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters').required('Password is required')

  });

  const { control, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(validationLogin),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = (data) => {
    displaySnackbar('success', 'Login Successfully');
     navigate('/imageUpload');
  };

  const handleForgotPassword = () => {
    navigate('/forgotPassword');
  };
  const handleSignUpPassword = () => {
    navigate('/signUp')
  }

  return (
    <div style={{ background: 'linear-gradient(135deg, #e0f7fa, #fff)' }}>
      <Box>
        <Grid container>
          {/* <Grid item xs={12} md={6}
            style={{
              display: "flex",
              justifyContent: 'center', alignItems: 'center'
            }}>
            <img src={LoginImg} alt='img' style={{ width: '500px' }} />
          </Grid> */}
          {!isMobile && !isTablet && !isTabletPortrait && (
            <Grid item xs={12} md={6}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <img src={LoginImg} alt='img' style={{ width: isTabletLandscape ? '400px' : '500px' }} />
              {/* <img
                src={LoginGif}
                alt='gif'
                style={{ width: isTabletLandscape ? '400px' : '500px' }}
              /> */}
            </Grid>
          )}
          <Grid item xs={12} md={isTabletPortrait ? 12 : 6} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Container
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '86vh',
                    width: isTabletPortrait ? '100%' : 'auto',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      border: '1px solid',
                      borderColor: 'grey.300',
                      padding: 2,
                      borderRadius: '16px',
                      backgroundColor: '#ffffff',
                      width: '350px',
                      // height: '600px'
                    }}
                  >
                    <Typography className='loginTitle'>User Login</Typography>

                    <Typography marginTop={1}
                      // className='loginSecondTitle'
                      style={{ fontStyle: 'italic', fontSize: '13px', textAlign: 'center', fontWeight: 'bold' }}
                    >
                      Hey, Enter your details to get sign in <br /> to your account
                    </Typography>

                    <Grid container xs={12} sx={{ marginTop: '10px' }} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <Grid item>
                        <Controller
                          control={control}
                          name='email'
                          render={({ field }) => (
                            <MuiTextField
                              {...field}
                              name='email'
                              size='small'
                              type='text'
                              label='Email'
                              error={!!errors.email}
                              helperText={errors.email?.message}
                              style={{
                                width: '300px',
                                height: '40px',
                                marginBottom: '1px',
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton edge="end" style={{ pointerEvents: 'none' }}>
                                      <EmailIcon sx={{ fontSize: 20 }} />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item>
                        <Controller
                          control={control}
                          name='password'
                          render={({ field }) => (
                            <MuiTextField
                              {...field}
                              name='password'
                              size='small'
                              type={showPassword ? 'text' : 'password'}
                              label='Password'
                              error={!!errors.password}
                              helperText={errors.password?.message}
                              style={{
                                width: '300px',
                                height: '40px',
                                marginTop: '10px',
                              }}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='end'>
                                    <IconButton onClick={handleClickShowPassword} edge='end'>
                                      {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>

                    <Grid marginTop={1.5} style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}>
                      <Typography style={{ fontSize: '14px' }}>
                        Forgot your Password?
                      </Typography>
                      <Typography
                        style={{ fontSize: '14px', cursor: 'pointer', color: 'blue', marginLeft: '8px', textDecoration: 'underline' }}
                        onClick={handleForgotPassword}
                      >
                        Click Here
                      </Typography>
                    </Grid>

                    <Grid container xs={12} marginTop={2}>
                      <Grid item xs={2}></Grid>
                      <Grid item xs={8} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
                        <MUIButton type='submit' title='Login' />
                      </Grid>
                      <Grid item xs={2}></Grid>
                    </Grid>

                    <Grid container marginTop={0.5} style={{ display: 'flex', flexDirection: 'row' }}>
                      <Grid item xs={3}></Grid>
                      <Grid item xs={6}>
                        <Divider sx={{ borderBottomWidth: 2 }}>Or Sign in with</Divider>
                      </Grid>
                      <Grid item xs={3}></Grid>
                    </Grid>

                    <Grid container marginTop={0.2} direction='column' alignItems='center' spacing={2}>
                      <Grid item>
                        <GoogleOAuthProvider clientId='<your_client_id>'>
                          <GoogleLogin
                            onSuccess={(credentialResponse) => {
                              console.log(credentialResponse);
                            }}
                            onError={() => {
                              console.log('Login Failed');
                            }}
                            useOneTap
                            render={(renderProps) => (
                              <CustomButton onClick={renderProps.onClick} disabled={renderProps.disabled}>
                                Sign in with Google
                              </CustomButton>
                            )}
                          />
                        </GoogleOAuthProvider>
                      </Grid>
                      <Grid item>
                        <AppleLogin
                          clientId='<your_apple_client_id>'
                          redirectURI='<your_redirect_uri>'
                          render={(renderProps) => (
                            <CustomButton
                              onClick={renderProps.onClick}
                              disabled={renderProps.disabled}
                              style={{ backgroundColor: '#000', '&:hover': { backgroundColor: '#333' } }}
                            >
                              <AppleIcon style={{ marginRight: 8 }} /> Sign in with iOS
                            </CustomButton>
                          )}
                          onSuccess={(response) => console.log(response)}
                          onError={(error) => console.log('Apple Login Failed:', error)}
                        />
                      </Grid>
                    </Grid>

                    <Grid container>
                      <Grid item style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end' }}> <Typography marginTop={1} style={{ fontSize: '14px' }}>
                        Not Registered Yet?
                      </Typography>
                        <Typography
                          style={{ fontSize: '14px', cursor: 'pointer', color: 'blue', marginLeft: '4px', textDecoration: 'underline' }}
                          onClick={handleSignUpPassword}
                        >
                          Create an Account
                        </Typography>
                      </Grid>
                      <Grid item></Grid>
                      <Grid item></Grid>
                    </Grid>
                  </Box>
                </Container>
              </form>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default Login;
